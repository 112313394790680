





































import KTextInput from '@/@core/components/input/KTextInput.vue';
import KPasswordInput from '@/@core/components/input/KPasswordInput.vue';
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  unref,
  watch
} from '@vue/composition-api';
import { required, minLength } from '@/@core/helpers/inputValidator';
import useModelValue from '@/@core/helpers/modelValue';
import { useUserStore } from '../services/store';
import { useDistrictStore } from '@/modules/district/services/store';
import KComboboxInput from '@/@core/components/input/KComboboxInput.vue';

export default defineComponent({
  components: { KTextInput, KPasswordInput, KComboboxInput },
  name: 'ClassificationForm',
  props: {
    isValid: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object as PropType<any>
    }
  },
  emits: ['valid'],
  setup(props, { emit, attrs }) {
    const modelValue = useModelValue({ emit, attrs });
    const reRenderCount = ref(0);
    const isLoading = ref(false);

    const isEdit = computed(() => modelValue.value?.id);

    const isValidValue = computed({
      get: () => props.isValid,
      set: (v: any) => emit('valid', !v)
    });

    const onSubmit = () => emit('submit', unref(modelValue));

    const isEmployee = computed(
      () => modelValue.value?.role == 'Petugas'
    );

    onMounted(async () => {
      if (!modelValue.value)
        modelValue.value = {
          district: undefined
        };

      if (isEdit.value) {
        modelValue.value.role = modelValue.value.role.alias;

        const hasDistrict = modelValue.value?.district;

        if (hasDistrict)
          modelValue.value.district = modelValue.value.district.name;
      }

      isLoading.value = true;
      await useUserStore().getManyRole();
      isLoading.value = false;
      reRenderCount.value++;
    });

    watch(isEmployee, (v) => {
      if (!v && modelValue?.value?.district)
        modelValue.value.district = undefined;
    });

    return {
      onSubmit,
      modelValue,
      required,
      isValidValue,
      reRenderCount,
      isLoading,
      isEdit,
      store: useUserStore(),
      districtStore: useDistrictStore(),
      minLength,
      isEmployee
    };
  }
});
