import { render, staticRenderFns } from "./UserForm.vue?vue&type=template&id=d232fbfa&"
import script from "./UserForm.vue?vue&type=script&lang=ts&"
export * from "./UserForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.8_m6jvttnwtqqy6l4pi5vsxiehsy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/.pnpm/vuetify-loader@1.7.3_a2zfgjqzsadyfn2vpg7vyla664/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VForm})
